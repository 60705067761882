<template>
	<li class="timeline-item">
		<div class="direction-r">
			<div class="flag-wrapper">
				<div class="flag">
					<span class="label">{{ props.label }}：</span>
					{{ props.value }}
				</div>
				<div class="f">
					<slot></slot>
				</div>
			</div>
		</div>
	</li>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
	label: {
		type: String,
		default: ''
	},
	value: {
		type: String,
		default: ''
	}
})

</script>

<style scoped>
.label {
	color: gray;
}
</style>

<template>
  <van-nav-bar title="成品油溯源码" fixed placeholder v-if="!isWechatCore" />
  <van-tabs
    v-model:active="active"
    sticky
    animated
    swipeable
    :offset-top="isWechatCore ? 0 : 40"
    @change="onChangeTab"
  >
    <van-tab title="油品追溯">
      <Trace />
    </van-tab>
    <van-tab title="油机检定">
      <CheckDetail />
    </van-tab>
    <van-tab title="投诉意见">
      <Comments />
    </van-tab>
  </van-tabs>
  <flat-button @select="onSelect" />
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isWechat } from "./utils/utils";
// Pages
import Trace from "./Views/Trace.vue";
import CheckDetail from "./Views/CheckDetail.vue";
import Comments from "./Views/Comments.vue";

// Components
import FlatButton from "./components/FlatButton.vue";

const router = useRouter();
const route = useRoute();

// const isWx = isWechat()
const isWechatCore = ref(isWechat());
const active = ref(route.query.tabIndex * 1 || 0);

const { site_code, tanker_name } = route.query;

const storeData = () => {
  localStorage.setItem("siteCode", site_code);
  localStorage.setItem("tankerName", tanker_name);
};

// 存储信息
storeData();

const onChangeTab = (e) => {
  router.push({
    path: "/",
    query: {
      site_code,
      tanker_name,
      tabIndex: e,
    },
  });
};

const onSelect = ({ index }) => {
  if (index === 0) {
    router.push({
      path: "/search",
    });
  } else {
    if (index === 1) {
      router.push({
        path: "/",
        query: {
          ...route.query,
          tabIndex: 1,
        },
      });
    } else if (index === 2) {
      router.push({
        path: "/",
        query: {
          ...route.query,
          tabIndex: 2,
        },
      });
    }
    active.value = index;
  }
};
</script>
<style>
.van-tabs__line {
  background: linear-gradient(to right, #ff3329, #ff7d43);
  box-shadow: 0px 4px 9px 1px rgba(255, 103, 96, 0.7);
  width: 50px;
  height:4px;
  border-radius: 0;
}
</style>
<template>
  <van-image
    :width="props.width"
    :height="props.height"
    :fit="props.fit"
    :src="props.src"
    :alt="`${item}号加油机检定合格证`"
    @click="onPreivewImage"
  >
    <template v-slot:error
      >
			<van-image width="200px" :src="state[type]"
    />
		</template>
  </van-image>

  <van-image-preview
    teleport="body"
    v-model:show="state.show"
    :images="state.images"
    @change="onChange"
  >
    <template #index>
      <slot name="index" />
    </template>
  </van-image-preview>
</template>

<script setup>
import { reactive, defineProps } from "vue";
// import traceCert from require('../assets/trace-cert.png')
// import traceCar from require('../assets/trace-car.png')
// import traceOutOrder from require('../assets/trace-outorder.png')

const props = defineProps({
  // 图片默认宽度，默认为 10px
  width: {
    type: String || Number,
    default: "100%",
  },
  // 图片默认高度，默认为 10px
  height: {
    type: String || Number,
    default: "100%",
  },
  // 图片链接
  src: {
    type: String,
  },
  alt: {
    type: String,
  },
  fit: {
    type: String,
    default: "fill",
  },
  imgList: {
    type: Array,
  },
  imgIndex: {
    type: String || Number,
  },
  item: {
    type: String || Number,
  },
  type: {
    type: String,
  },
});

const state = reactive({
  show: false,
  index: 0,
  certImg: require('../assets/trace-cert.png'),
  outOrderImg: require('../assets/trace-outorder.png'),
  carImg: require('../assets/trace-car.png'),
});
const onPreivewImage = (e) => {
  console.log(e.target.src);
  state.images = [e.target.src];

  state.show = true;
};
const onChange = (index) => {
  state.index = index;
};
</script>

<style>
.van-image__error {
  background: transparent;
}
</style>
export function isWechat() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}

export const toFixed = (n) => {
	if (isNaN(n)) {
		throw new Error('the variable is not a number')
	}

	return Number(n).toFixed(2)
}

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import {
    ConfigProvider,
    Button,
    Tabs,
    Tab,
    Form,
    Field,
    CellGroup,
    Cell,
    Collapse,
    CollapseItem,
    Tag,
    Popup,
    Picker,
    Image,
    ImagePreview,
    Grid,
    GridItem,
    Sticky,
    Popover,
    NavBar,
    Uploader,
    DatetimePicker,
    Swipe,
    SwipeItem,
    Divider,
    Empty,
    Icon
} from "vant";

const app = createApp(App);

app.use(ConfigProvider);
app.use(Button);
app.use(Tabs);
app.use(Tab);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Cell);
app.use(Collapse);
app.use(CollapseItem);
app.use(Tag);
app.use(Popup);
app.use(Picker);
app.use(Image);
app.use(ImagePreview);
app.use(Grid);
app.use(GridItem);
app.use(Sticky);
app.use(Popover);
app.use(NavBar);
app.use(Uploader);
app.use(DatetimePicker);
app.use(Swipe);
app.use(SwipeItem);
app.use(Divider);
app.use(Empty);
app.use(Icon);

app.use(router);

app.mount("#app");

<template>
  <div class="layout" style="padding-top: 16px">
    <van-config-provider :theme-vars="cellThemeVars">
      <van-cell-group inset class="cell-group">
        <van-cell
          title="加油站名称"
          value-class="cell-value"
          :value="checkInfo.siteName"
        />
        <van-cell
          title="加油机编号"
          value-class="cell-value"
          :value="checkInfo.tankerName"
        />
      </van-cell-group>
      <van-cell-group v-if="checkInfo.num" title="油品检定信息" inset class="cell-group">
        <van-cell
          title="器具编号"
          value-class="cell-value"
          :value="checkInfo.num"
        />
        <van-cell
          title="有效期至"
          value-class="cell-value"
          :value="checkInfo.expireDate"
        />
        <van-cell
          title="检定机构"
          title-class="cell-text"
          value-class="cell-value"
          :value="checkInfo.measureExamOrg"
        />
        <van-cell
          title="举报电话"
          value-class="cell-value"
          :value="checkInfo.complaint"
        />
      </van-cell-group>
      <van-cell-group
        v-if="checkInfo.spotTestResult.length > 0"
        inset class="cell-group"
        title="油品抽检"
        style="margin-bottom: 16px;"
      >
        <template
          v-for="(item, index) in checkInfo.spotTestResult"
          :key="index"
        >
          <van-cell
            title="加油枪编号"
            value-class="cell-value"
            :value="`${item.gunNum}号加油枪`"
          />
          <van-cell title="计量检定结果" value-class="cell-value">
            <template #value>
              <!-- <van-tag
                :type="item.spotTestResult == '合格' ? 'success' : 'danger'"
                size="medium"
              >{{ item.spotTestResult }}</van-tag> -->
              <span
                :class="'info-success'"
                :style="{
                  color: item.spotTestResult == '合格' ? '#1D62ED' : '#FF3329'
                }"
                >{{ item.spotTestResult }}</span
              >
            </template>
          </van-cell>
          <van-cell
            title="计量检定时间"
            value-class="cell-value"
            :value="item.spotTestDate"
          />
          <van-divider
            v-if="index == checkInfo.spotTestResult.length - 1 && index !== 0"
            style="border-color: transparent"
          ></van-divider>
        </template>
      </van-cell-group>
    </van-config-provider>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getMachineCheck } from "../api";

const siteCode = localStorage.getItem("siteCode");
// const siteName = localStorage.getItem('siteName')
const tankerName = localStorage.getItem("tankerName");

const cellThemeVars = {
  cellTextColor: "var(--van-gray-6)",
  cellValueColor: "var(--van-black)",
  cellGroupTitleColor: "var(--van-gray-7)",
  cellGroupInsetTitlePadding: "16px 8px 8px 24px",
  cellGroupTitleFontSize: "var(--van-font-size-lg)",
};

const checkInfo = ref({
  name: "",
  num: "",
  expireDate: "",
  measureExamOrg: "",
  complaint: "12315",
  spotTestResult: "",
  spotTestDate: "",
});

onMounted(async () => {
  const res = await getMachineCheck({
    siteCode,
    tankerName,
  });
  if (res.status === 0) {
    checkInfo.value = res.data;
  }
});
</script>

<style>
.cell-text {
  flex: 0 1 1;
}
.cell-value {
  flex: 2;
  font-size: 16px;
  width: 100%;
}
</style>

<style scoped>
.layout {
  box-sizing: border-box;
  min-height: calc(100vh - 90px);
  /* background: #fff; */
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  background: #fff;
  margin-bottom: 10px;
  padding: 12px 16px;
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  letter-spacing: 1px;
}
.note {
  font-size: 14px;
  color: #666;
}

.check-item {
  margin-top: 16px;
}
.check-item:not(:last-child) {
  margin-bottom: 16px;
}
.check-item__title {
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 1px;
  color: #333;
}

.spot-test .date {
  color: #1989fa;
}

</style>
<style>
.cell-group {
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}
</style>
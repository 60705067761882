<template>
  <ul
    class="timeline"
    :style="{
      '--color': props.color,
      '--borderColor': props.borderColor,
    }"
  >
    <slot></slot>
  </ul>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#1D62ED",
  },
  borderColor: {
    type: String,
    default: "#ABCBFF",
  },
});
console.log()
</script>

<style>
.timeline {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.timeline::before {
  position: absolute;
  content: "";
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  background: linear-gradient(to bottom, #d4d4d4 8px, transparent 8px) repeat-y;
  background-size: 1px 16px;
}

.timeline-item {
  position: relative;
  margin-bottom: 24px;
}

.timeline-item::before {
  position: absolute;
  top: 3px;
  left: 7px;
  content: "";
  width: 10px;
  height: 10px;
  /* background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjI4MjIwNDA4Mjk5IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjE5NDUxIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTI0Ni40OTk1NTYgNDQ4TDExMy43Nzc3NzggNTgwLjcyMTc3OGwzOTcuMDI3NTU1IDM5OC4yMjIyMjIgMzk5LjQxNjg4OS0zOTguMjIyMjIyLTEzMi43MjE3NzgtMTMyLjcyMTc3OEw1MTIgNzEzLjUwMDQ0NHoiIGZpbGw9IiMyYWE1MTUiIHAtaWQ9IjE5NDUyIj48L3BhdGg+PHBhdGggZD0iTTI0Ni40OTk1NTYgMEwxMTMuNzc3Nzc4IDEzMi43MjE3NzhsMzk3LjAyNzU1NSAzOTguMjIyMjIyIDM5OS40MTY4ODktMzk4LjIyMjIyMkw3NzcuNTAwNDQ0IDAgNTEyIDI2NS41MDA0NDR6IiBmaWxsPSIjMmFhNTE1IiBvcGFjaXR5PSIuNiIgcC1pZD0iMTk0NTMiPjwvcGF0aD48L3N2Zz4=") */
  /* no-repeat; */
  /* background-size: cover; */
  background: var(--color);
  border: 4px solid var(--borderColor);
  /* background: #1D62ED;
    border: 4px solid #ABCBFF; */
  border-radius: 50%;
}

.direction-r {
  margin-left: 36px;
  margin-bottom: 12px;
  background-color: #fff;
}

.flag-wrapper {
  padding: 0 12px;
  box-sizing: border-box;
}

.flag-wrapper .flag {
  display: flex;
  justify-content: space-between;
}

.desc {
  padding: 12px;
  box-sizing: border-box;
}
</style>